<template>
	<div class="frightTool">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="请输入活动名称" maxlength="20" style="width: 300px;"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="activeState" clearable filterable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in activeStateList " :key="item.id" :value="item.id" :label="item.value"></el-option>
				</el-select>
			</div>

			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
				<buttonPermissions :datas="'addFreeShipping'">
					<el-button type="primary" style="margin-left:30px" @click="addFright({},0)">添加</el-button>
				</buttonPermissions>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable">
				<el-table-column prop="Name" label="活动名称"></el-table-column>
				<el-table-column prop="addTime" label="活动时间" width="170">
					<template slot-scope="scope">
						<span v-if="scope.row.StartTime=='2000-01-01 00:00:00'&&scope.row.EndTime=='4000-01-01 00:00:00'">永久有效</span>
						<span v-else>{{scope.row.StartTime}}至{{scope.row.EndTime}}</span>

					</template>
				</el-table-column>
				<el-table-column prop="FullMoney" label="活动内容">
					<template slot-scope="scope">
						满{{scope.row.FullMoney}}元包邮
					</template>
				</el-table-column>
				<el-table-column prop="SendTypeValue" label="配送方式"></el-table-column>
				<el-table-column prop="ActivityShopRangeTypeValue" label="适用门店">
					<!-- <template slot-scope="scope"></template> -->
				</el-table-column>
				<el-table-column prop="ActivityStateShow" label="状态"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<el-button type="text" style="margin-right:15px" @click="lookTooll(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">查看</el-button>
						<buttonPermissions :datas="'editFreeShipping'">
							<el-button type="text"  style="margin-right: 15px;"  @click="addFright(scope.row,1)" v-if="scope.row.ActivityStateShow!='已结束'">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'cancelFreeShipping'">
							<el-button type="text"  style="margin-right: 15px;"  @click="makeStop(scope.row)" v-if="scope.row.ActivityStateShow!='已结束'">使终止</el-button>
						</buttonPermissions>
						<buttonPermissions   style="margin-right: 15px;"  :datas="'copyFreeShipping'">
							<el-button type="text" @click="addFright(scope.row,2)">复制</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'deleteFreeShipping'">
							<span style="color:#f00;margin-right:15px;cursor: pointer;" @click="deleteChose(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">删除</span>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>



	</div>

</template>

<script>
	import {
		activityFreeDeliveryList,
		activityFreeDeliveryopen,
		activityFreeDeliveryDelete
	} from '@/api/goods'
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				searchKey: '',
				activeState: null,
				activeStateList: [{
						id: 0,
						value: '未开始'
					},
					{
						id: 1,
						value: '进行中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 3,
				loading: false


			}
		},
		beforeMount() {
			this.getList();
		},
		methods: {
			// 获取列表数据
			async getList() {

				this.loading = true;
				try {
					let data = {
						KeyWord: this.searchKey,
						ActivityState: this.activeState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await activityFreeDeliveryList(data);
					// console.log(result, '表格的数据')

					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

			handleFilter() {
				this.currentPage = 1;
				this.getList();
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},

			// 删除
			async deleteChose(record) {
				this.delteLoading = true
				this.$confirm('是否确认确认删除活动?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let data = {
						Id: record.Id
					}
					let result = await activityFreeDeliveryDelete(data);
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
					}
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.handleFilter()
				})
			},

			// 使终止
			async makeStop(record) {
				this.delteLoading = true
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终？，', '提示', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',

				}).then(async () => {
					let data = {
						Id: record.Id
					}
					let result = await activityFreeDeliveryopen(data);
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消终止'
					});
				}).finally(() => {
					this.handleFilter()
				})
			},
			// 编辑和新增
			addFright(record, type) {

				this.$router.push({
					path: '/market/addFeighttool',
					query: {
						Id: record.Id ? record.Id : 0,
						type: type
					}
				});
			},

			// 查看包邮工具
			lookTooll(record) {

				this.$router.push({
					path: '/market/addFeighttool',
					query: {
						Id: record.Id ? record.Id : 0
					}
				});
			},


		},


	}
</script>

<style lang="less">
	.frightTool {
		width: 100%;
		background: #fff;
		padding: 20px;
	}
</style>
